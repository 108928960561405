import React from "react";
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import { Fade, Slide } from 'react-reveal';
import favicon from '../images/favicon.ico';
import Wave from 'react-wavify';

// components
import Testimonial from "../components/testimonial";
import LatestNews from "../components/news/news";
import MyCarousel from "../components/carousel/carousel";
import SupportCard from "../components/supportCard";

//images
import globalconnect_img from "../images/partners_logor/globalconnect.png";
import koenigsegg_img from "../images/partners_logor/koenigsegg.png";
import monitor_img from "../images/partners_logor/1_monitor.jpg";

import ta_emot_icon from "../images/icons/ta-emot.svg";
import skicka_icon from "../images/icons/skicka.svg";
import edi_icon from "../images/icons/edi.svg";
import p27_icon from "../images/icons/p27.svg";

function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Crediflow AB</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta name="description" content="Crediflow digitizes and automates incoming and outgoing flows of invoices and electronic documents." />
        <meta name="keywords" content="incoming invoices | outgoing invoices | automate electronic documents" />
        <meta property="og:title" content="Crediflow AB" />
        <meta property="og:tyope" content="website" />
        <meta property="og:discription" content="Crediflow digitizes and automates incoming and outgoing flows of invoices and electronic documents." />
        <meta property="og:image" content="" />
        <meta property="og:locale" content="en_EN" />
        <meta property="og:url" content="http://www.crediflow.com/" />
        <link rel="cannonical" href="http://www.crediflow.com/" />
      </Helmet>

      {/***********  Hero Section - END  **********/}
      <section className="bg-gray-100 pb-24">
        <div className="w-screen bg-hero-banner bg-top bg-no-repeat bg-cover">
          <div className="container mx-auto px-4 xl:px-24">
            <div className="py-24">
              <h1 className="w-full lg:w-5/6 mx-auto text-center  text-4xl md:text-5xl  font-bold md:leading-snug  text-white filter drop-shadow-sm">We digitize and automate business processes!</h1>
              <p className="md:w-2/3 mx-auto text-center mt-10 lg:text-xl text-white">
                Our services are used today in the majority of financial 
                and business systems on the Swedish and European market 
                to digitize and automate incoming and outgoing flows of 
                invoices and electronic documents. Together, we create 
                the conditions for companies to be able to digitize 
                their processes in a simple way and work simpler and smarter.
              </p>
            </div>
          </div>
          <div>
            <Wave 
              className="-mb-2"
              fill='#F3F4F6'
              paused={false}
              options={{
              height: 20,
              amplitude: 50,
              speed: 0.15,
              points: 3
              }}
            />
          </div>
        </div>
        <div className="container mx-auto px-4 xl:px-24 -mt-36">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/supplier-invoices">
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={ta_emot_icon} alt="Supplier invoices"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Supplier invoices</h2>
                  <p className="mt-4 text-gray-500">
                    One channel in for all your invoices regardless of whether
                    it is e-invoice, paper or pdf. Flexible
                    to choose which parts you want to make yourself or outsource.
                  </p>
                </div>
              </Link>
            </div>

            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/customer-invoices"  >
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={skicka_icon} alt="Customer invoices"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">Customer invoices</h2>
                  <p className="mt-4 text-gray-500">
                    Regardless of how your customer wishes to 
                    receive invoices from you, we have the 
                    solution. E-invoice, e-mail, print or 
                    digital boxes such as Kivra.
                  </p>
                </div>
              </Link>
            </div>

            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/edi">
                <div className="h-full w-full px-6 py-8">
                  <div className="w-20 mx-auto">
                    <img className="" src={edi_icon} alt="EDI"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">EDI</h2>
                  <p className="mt-4 text-gray-500">
                    We offer a complete solution for
                    EDI regardless of whether you want to send or receive EDI messages.
                  </p>
                </div>
              </Link>
            </div>
        
            <div className="bg-white rounded-md shadow-2xl hover:shadow-lg transition-all transform hover:scale-105 lg:hover:-mt-4 lg:hover:mb-4">
              <Link to="/sdc">
                <div className="h-full w-full px-6 py-8 ">
                  <div className="w-20 mx-auto">
                    <img className="" src={p27_icon} alt="SDC"/>
                  </div>
                  <h2 className="mt-4 font-semibold text-center">SDC</h2>
                  <p className="mt-4 text-gray-500">
                    SDC is a digital platform for exchange
                    of sensitive and unstructured information
                    in a secure and encrypted way.
                  </p>
                </div>
              </Link>
            </div>

          </div>
        </div>
      </section>
      {/***********  Hero Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  Carousel Section - START  **********/}
      <section className="w-screen bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Partners</h1>
          <p className="mt-4 text-center text-gray-600">Do like others, experience the wholeness!</p>
          <div className="w-full mt-14">
            <MyCarousel />
          </div>
        </div>
      </section>
      {/***********  Carousel Section - END  **********/}



      
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>




      {/***********  About Section - START  **********/}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4 xl:px-24">
          <div className="grid xl:grid-cols-2 gap-32 lg:gap-20">
            <Slide left>
              <div className="w-full">
                <h2 className="title-font font-semibold text-3xl text-gray-900">Can a modern company have over 25 years of experience?</h2>
                <p className="leading-relaxed mt-6 text-gray-600">
                  The answer is simple &#8211; YES! We have over 25 
                  years of experience and, with our deliveries to 
                  public authorities as well as to small companies, 
                  we have enormous experience in the market we operate in.
                  <br/><br/>
                  Our group today has a turnover of close to 100 million per 
                  year and has had a Triple AAA rating for several years, 
                  which means that you get a financially strong supplier 
                  who also loves technology. Our customers thus get to 
                  take part in the most modern technology available 
                  on the market such as AI, machine learning RPA etc.
                  <br/><br/>
                  We want to make your journey in digitization easy, 
                  let us take you into the future. Did you know 
                  that we also save money for our customers and partners?
                  <br/><br/>
                  See below what some of our customers say about us, 
                  then contact us for a demo or a conversation about your needs.
                </p>            
              </div>
            </Slide>
            <Slide right>
                <div className="w-full mx-auto flex items-start justify-center bg-shape bg-no-repeat bg-contain bg-top">
                  <div className="mt-14 lg:mt-0 flex justify-center items-center" style={{width: "25rem"}}>
                    <div className="w-10/12 transform rotate-1 sm:rotate-3 ">
                      <div className="custom_3d_img transform -skew-x-2  sm:-skew-x-6 pr-3 pb-3 bg-gradient-to-br from-blue-400 to-blue-900" >
                        <div className="bg-gradient-to-tl from-gray-400 to-blue-800 rounded-3xl pb-3 pt-2 pl-1 pr-2">
                          <div className="bg-white rounded-3xl overflow-hidden" style={{height: "550px"}}>
                            <div className="h-full w-full flex flex-col justify-between">
                              <iframe src="https://bot.leadoo.com/bot/inpage.html?code=QtxTmsUN" style={{border: "none", width: "100%", height: "380px"}} title="crediflow ai"/>
                              <div className="w-full h-40 m-1.5 bg-no-repeat bg-keyboard bg-contain bg-bottom border-t border-gray-200"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </Slide>
          </div>
        </div>
      </section>
      {/***********  About Section - END  **********/}





      {/***********  Review Section - START  **********/}
      <section className="bg-gray-100 py-24">
        <div className="container mx-auto px-4 xl:px-24">
          <Slide bottom>
            <div className="grid lg:grid-cols-3 gap-8">
              <Testimonial company="Global Connect" logo={globalconnect_img}>
                <div className="h-full lg:h-52 xl:h-48 2xl:h-32">
                    <p className="mt-2 text-gray-600">
                      Crediflow are incredibly responsive and their
                      solution CrossState eVoyce has facilitated
                      our work lots!
                    </p>
                </div>

              </Testimonial>

              <Testimonial company="Koenigsegg" logo={koenigsegg_img}>
                <div className="h-full lg:h-52 xl:h-48 2xl:h-32">
                    <p className="mt-2 text-gray-600">
                      Koenigsegg strives to reach the ultimate performance. 
                      Smooth and fast invoice processing through Crediflow 
                      is a cog in the wheel, so we can reach our goals
                    </p>
                </div>
              </Testimonial>

              <Testimonial company="MONITOR" logo={monitor_img}>
                <div className="h-full lg:h-52 xl:h-48 2xl:h-32">
                    <p className="mt-2 text-gray-600">
                      The collaboration with Crediflow, a responsive 
                      and committed supplier, provides high quality in 
                      our final product and is an important part of 
                      maintaining a world-class business system.
                    </p>
                </div>

              </Testimonial>
            </div>
          </Slide>
        </div>
      </section>
      {/***********  Review Section - END  **********/}
      


      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  Stats Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24 ">
        <div className="container mx-auto xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">You will not be alone!</h1>
          <p className="px-4 mt-4 w-full md:w-1/2 xl:w-1/3 mx-auto text-center text-gray-600">We currently handle a large part of all invoices that are sent and received in the Nordics and other countries!</p>
          <div className="mt-14 bg-world bg-cover bg-no-repeat bg-top">
            <Fade>
              <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12 lg:gap-20 pt-12">
                <div className="w-44 h-44 md:w-40 md:h-40 lg:w-44 lg:h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">200 000</h1>
                  <p className="text-gray-600">Per day</p>
                </div>

                <div className="w-44 h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">8 500 000</h1>
                  <p className="text-gray-600">Per month</p>
                </div>

                <div className="w-44 h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">45 000 000</h1>
                  <p className="text-gray-600">Per year</p>
                </div>

                <div className="w-44 h-44 mx-auto rounded-full pt-4 flex flex-col items-center justify-center shadow-xl" style={{background: "rgba(265, 265, 265, 0.75)"}}>
                  <h1 className="text-3xl font-bold text-center text-gray-800">23</h1>
                  <p className="text-gray-600">Countries</p>
                </div>
              </div>
            </Fade>
            <div className="bg-gradient-to-b from-transparent via-transparent to-gray-100 md:h-32"></div>
          </div>
        </div>
      </section>
      {/***********  Stats Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  News Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24 ">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">News</h1>
          <p className="mt-4 text-center text-gray-600">Latest news from Crediflow</p>
          <div className="mt-14">
            <LatestNews />
          </div>
          <div className="w-full mt-14 flex justify-center items-center">
            <Link to="/news" className="px-6 py-2 rounded-full text-white bg-blue-custome shadow-md hover:opacity-90">Curious about more news?</Link>
          </div>
        </div>
      </section>
      {/***********  News Section - END  **********/}
      

      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>


      {/***********  Support Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Customer Center</h1>
          <p className="mt-4 text-center text-gray-600">It is us you will meet when you choose Crediflow!</p>
          <div className="mt-14 grid  md:grid-cols-2 xl:grid-cols-3 gap-8">

            <SupportCard
              name="Kjell Bergendahl"
              position="Support Team Stockholm"
              phone=""                        
            />
            <SupportCard
                name="Ann-Kristine Pettersson"
                position="Front Office Stockholm &amp; Helsingborg"
                phone="08-578 604 00"                        
            />
            <SupportCard
                name="Nicklas Nylén"
                position="Support Team Stockholm"
                phone=""                        
            />
            <SupportCard
                name="Adam Fins"
                position="Support Team Stockholm"
                phone=""                        
            />
            <SupportCard
                name="Felix Oxing"
                position="Support Team Helsingborg"
                phone=""                        
            />
            <SupportCard
                name="Jonas Schrewelius"
                position="Support Team Helsingborg"
                phone=""                        
            />
          </div>
          <div className="w-full mt-14 flex justify-center items-center">
            <Link to="/about-us" className="px-6 py-2 rounded-full text-white bg-blue-custome shadow-md hover:opacity-90">Meet more of us!</Link>
          </div>
        </div>
      </section>
      {/***********  Support Section - END  **********/}



      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-4 xl:px-24">
          <hr className="text-gray-200"/>
        </div>
      </section>



      {/***********  Map Section - START  **********/}
      <section className="bg-gray-100 pt-20 pb-24">
        <div className="container mx-auto px-4 xl:px-24">
          <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Our offices</h1>
          <p className="mt-4 text-center text-gray-600">We are closer than you think!</p>
          <div className="grid lg:grid-cols-2 gap-8 mt-14">
            <div>
              <iframe 
                className="w-full h-80 rounded-lg shadow-lg"
                title="google_maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1664.8589882283513!2d18.092931875594783!3d59.44172626589987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9bee7fbb256b%3A0x97ee9d120068107b!2sOptosweden%20AB!5e0!3m2!1sen!2sus!4v1604924344158!5m2!1sen!2sus" 
              />
              <h3 className="text-xl text-center mt-10 font-semibold ">Stockholm</h3>
              <p className="mt-1 text-gray-600 text-center">Fogdevägen 4B<br/>183 64 Täby, Stockholm</p>
            </div>
     
            <div>
              <iframe 
                className="w-full h-80 rounded-lg shadow-lg"
                title="google_maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2228.3167910685897!2d12.693077015942004!3d56.04781748063327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46523235de5cf011%3A0x88c55dc9cd6a60e4!2sNorra%20Storgatan%208%2C%20252%2020%20Helsingborg!5e0!3m2!1ssv!2sse!4v1612899702765!5m2!1ssv!2sse" 
              />
              <h3 className="text-xl text-center mt-10 font-semibold ">Helsingborg</h3>
              <p className="mt-1 text-gray-600 text-center">Norra Storgatan 8<br/>252 20 Helsingborg, Skåne</p>
            </div>
            
          </div>
        </div>
      </section>
      {/***********  Map Section - END  **********/}


      
    </Layout>
  );
}

export default Home;